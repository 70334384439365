import React from "react"
import Wrapper from '../components/layout/wrapper'
import Layout from "../components/layout/layout"
import SEO from "../components/seo"

const ContactPage = () => (
  <Layout>
    <SEO title="Let's chat" />
   <Wrapper>
   <h1>Hey!</h1>
    <p>Let's chat more...</p>
    <form method="post" netlify-honeypot="bot-field" data-netlify="true">
    <input type="hidden" name="bot-field" />
  <label>
    Name
    <input type="text" name="name" id="name" />
  </label>
  <label>
    Email
    <input type="email" name="email" id="email" />
  </label>
  <button type="submit">Send</button>
  <input type="reset" value="Clear" />
</form>
   </Wrapper>
  </Layout>
)

export default ContactPage